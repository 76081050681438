import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
 import { message } from "antd";
import { useHistory } from "react-router-dom";
import { httpAPI } from "../../api";
import { adminRoutes } from "../../helpers/adminRoutes";
 
const initialState = {
  user: null,
  accessToken: "",
  loading: false,
  isLoggedIn: false,
  error: null,
  faviconImage: "/images/newDashboardLogo.png",
  landingPageLogo: "/images/loginhealthlife.png",
  backgroundImage: "/images/bg-min.png",
  homePageLogo: "/images/newDashboardLogo.png",
};


export const login = createAsyncThunk("user/login", async (data, thunkAPI) => {
  try {
    const response = await httpAPI("/authentication", data);
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

 

export const me = createAsyncThunk("user/me", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("userId");
    const response = await httpAPI(
      `/users/${id}`,
      data,
      "GET"
    );
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.user = null;
      state.accessToken = "";
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
      state.faviconImage = initialState.faviconImage;
      state.landingPageLogo = initialState.landingPageLogo;
      state.backgroundImage = initialState.backgroundImage;
      state.homePageLogo = initialState.homePageLogo;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
       localStorage.setItem("token", payload.accessToken);
      localStorage.setItem("userId", payload.user._id);
      state.user = payload.user;
      state.faviconImage = initialState.faviconImage;
      state.landingPageLogo = initialState.landingPageLogo;
      state.backgroundImage = initialState.backgroundImage;
      state.homePageLogo = initialState.homePageLogo;
      state.accessToken = payload.accessToken;
      state.isLoggedIn = true;
      state.loading = false;
      state.error = null;
     },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
    },
     
    [me.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.faviconImage = initialState.faviconImage;
      state.landingPageLogo = initialState.landingPageLogo;
      state.backgroundImage = initialState.backgroundImage;
      state.homePageLogo = initialState.homePageLogo;
    },

    
  },
});

// Action creators are generated for each case reducer function
export const { logout } = counterSlice.actions;

export default counterSlice.reducer;

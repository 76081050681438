import { httpAPI } from "../index";

const EmailAPI = {
  addEmail(params) {
    return httpAPI(`/email`, params, "post");
  },
  getEmail(query,params) {
    return httpAPI(`/email?${query || ""}`, params, "get");
  },
  resetPassword(id, params) {
    return httpAPI(`/email/${id}`, params, "patch");
  },
  verifyEmail(id, params) {
    return httpAPI(`/email/${id}`, params, "patch");
  },
};

export default EmailAPI;

import React, { useState,useEffect,useCallback } from "react";
import dayjs from "dayjs";
import { PrimaryButton, SecondaryButton } from "../../ui/Button";
import { Card } from "../../ui/Card";
import { Datatable, UserAvatarAndFullName } from "../../ui/Datatable";
import { Heading } from "../../ui/Heading";
import { Container } from "../../ui/Layout/Container";
 import Actions from "../../ui/Datatable/Actions";
import { ToggleSwitch } from "../../ui/Form";
import { adminRoutes } from "../../helpers/adminRoutes";
import { Button,message } from "antd";
import { useHistory } from "react-router-dom";
import CLientAPI from "../../api/http/client";
import { paginationObj, querystring, searchObj } from "../../utils/fn";

const Dashboard = () => {
  const history = useHistory();

  const [data, setData] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  function findInValues(arr, value) {
    value = String(value).toLowerCase();
    return arr.filter((o) =>
      Object.entries(o).some((entry) =>
        String(entry[1]).toLowerCase().includes(value)
      )
    );
  }


  useEffect(() => {
    getClientList(pagination);
  }, []);

  const getClientList = useCallback((pagination) => {
    CLientAPI.getClientList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data?.data)
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  }, []);

  const handleInviteClient = () => {
    history.push(adminRoutes.INVITE);
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];


  const handleDelete = (id, item) => {
     CLientAPI.deleteClient(item, { id: item })
      .then(({ data }) => {
        getClientList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(adminRoutes.EDITCLIENT.replace(":id", item));
        break;
      case "delete":
        handleDelete(":id", item);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_) => {
        return <ToggleSwitch defaultChecked={_} onChange={console.log} />;
      },
    },
    {
      title: "Name",
      dataIndex: "clientName",
      key: "clientName",
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      render: (_, record) => {
        return <UserAvatarAndFullName name={_} image={record.image} />;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },

    {
      title: "Business Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    // {
    //   title: "Date Joined",
    //   dataIndex: "joinDate",
    //   key: "joinDate",
    //   sorter: (a, b) => a.joinDate - b.joinDate,
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(["name","clientName","email"], search);
     setPagination((p) => ({ ...p, ...page, filter ,sorter  }));
    getClientList({ ...pagination, ...page, filter,sorter });
  };

  return (
    <Container>
      <Card>
        <Datatable
          columns={columns}
          dataSource={data}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryButton onClick={handleInviteClient}>
              Invite Client
            </SecondaryButton>
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default Dashboard;

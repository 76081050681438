import React,{useEffect} from "react";
import { Button, Form, Input, Checkbox,message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { adminRoutes } from "../helpers/adminRoutes";
import styled from "styled-components";
import { authRoutes } from "../helpers/adminRoutes";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/reducers/user.reducer";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  min-height: 100vh;
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
`;

const StyledRightContainer = styled.div`
  display: flex;
  min-height: 100vh;
  padding: 38px;
  width: 45%;
  background-color: white;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 11;
  @media screen and (max-width: 959px) {
    width: 100%;
    padding: 14px;
  }
`;

const StyledFormItem = styled.div`
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 470px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
`;

const StyledLeftContainer = styled.div`
  display: flex;
  padding: 38px;
  position: relative;
  background-image: url("/images/${(props) =>
    props.backgroundImg ? props.backgroundImg : `nasa.jpg`}");
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.primary};
  background-position: center;
  background-size: cover;

  @media screen and (min-width: 960px) and (max-width: 5000px) {
    width: 55%;
    min-height: 100vh;
    // ::after {
    //     position: absolute;
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     // background-color:${(props) =>
      props.background ? props.background : "#00000099"};
    // }
  }
  @media screen and (max-width: 959px) {
    width: 100%;
    min-height: 333px;
    height: 333px;
  }
`;

const StyledLoginSignButtonWrapper = styled.div`
  position: absolute;
  top: 161px;
  right: -121px;
  left: unset;
  display: flex;
  transform: rotate(-90deg);
  .ant-btn-join {
    height: 68px;
    width: 140px;
    z-index: 10;
    background-color: #ededed;
    color: #747487;
    background: #ededed;
    border-radius: 10px 10px 0px 0px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary};
    @media screen and (max-width: 959px) {
      width: 140px;
      height: 58px;
    }
  }
  .ant-btn-login {
    margin-right: 30px;
    height: 68px;
    width: 140px;
    z-index: 10;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #747487;
    @media screen and (max-width: 959px) {
      margin-right: 20px;
      width: 140px;
      height: 58px;
    }
  }
  @media screen and (max-width: 959px) {
    left: 50%;
    transform: rotate(0deg);
    bottom: 0;
    top: unset;
    right: unset;
    transform: translate(-50%, 0);
  }
`;

const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: white;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

const StyledForms = styled.div`
  padding-top: 32px;

  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
  @media screen and (max-width: 959px) {
    padding-top: 0px;
  }
`;

const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 959px) {
    display: none;
  }
`;

const StyledLogoLabel = styled.div`
  position: relative;
  height: max-content;
  width: 100%;
  text-align: center;
  z-index: 1;
  font-size: 20px;
  line-height: 20px;
  color: #949494;
  font-weight: bold;
  @media screen and (max-width: 595px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const StyledLink = styled.div`
  font-size: 14px;
  color: #747487;
  margin-left: 4px;
  font-weight: 600;
`;

const StyledForgotPassword = styled.ul`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
`;

const Login = () => {
  const history = useHistory();
   const dispatch = useDispatch();

   const { error, isLoggedIn, loading } = useSelector((state) => state.user);

  const onFinish = (values) => {
    values.strategy = "local";
    values.email=(values.email).toLowerCase();
    dispatch(login(values));
  };

  useEffect(() => {
    console.log("isLoggedIn",isLoggedIn)
    if (error) {
      message.error(error.message);
    } else if (isLoggedIn) {
      // message.success("Logged In successfully");
       history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoggedIn]);

  return (
    <StyledForm>
      <StyledFormsLabel>Login</StyledFormsLabel>
      <StyledForms>
        <Form
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={"optional"}
        >
          <StyledFormItem>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
          </StyledFormItem>
          <Form.Item
            name="password"
            label="Password"
            className="password-w-icon"
            rules={[
              {
                required: true,
                message: "This is required",
              },
              {
                min: 8,
                message: "password should contain minimum 8 characters",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              prefix={
                <img
                  src="/assets/images/password-icon.png"
                  alt=""
                  className="user-icon"
                />
              }
            />
          </Form.Item>
          <div className="remember-wrapper">
            <Form.Item
              className="remember-check"
              name="remember"
              valuePropName="checked"
              initialValue=""
            >
              <Checkbox className="text-white remember-txt">
                Remember me
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Link
                className="text-white forget-txt"
                to={authRoutes.FORGOT_PASSWORD}
              >
                {" "}
                <StyledForgotPassword>Forgot Password?</StyledForgotPassword>
              </Link>
            </Form.Item>
          </div>
          <Form.Item>
            <StyledButton
              // btnColor=""
              htmlType="submit"
            >
              Log In
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
};

export default Login;

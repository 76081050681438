import React, {useState} from 'react';
import { StyledHeaderSearch } from './TheHeader.styles';
import { TreeSelect } from "antd";
import { FiSearch } from "react-icons/fi";
import "./style.css";

const { TreeNode } = TreeSelect;

const HeaderSearch = () => {

    const [value, setValue] = useState(undefined);

    const onChange = () => {
        setValue(value);
    };

    return (
        <>
            <StyledHeaderSearch>
                <FiSearch className="header_search_icon" />
                <TreeSelect
                    showSearch={true}
                    showArrow={false}
                    listHeight={628}
                    style={{ width: "100%" }}
                    value={value}
                    placeholder="Search..."
                    treeDefaultExpandAll={true}
                    onChange={onChange}                 
                    dropdownClassName="header_tree_select_dropdown"
                >
                <TreeNode
                    value="Connect"
                    title="Connect"
                    inputColor="rebeccapurple"
                    textType="treeMainText"
                    style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#232333",
                        lineHeight: "20px"
                    }}
                >
                    <TreeNode
                        value="Relationship"
                        title="Relationship"
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                    >
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Walter Rogers wrogers@gmail.com"
                        title="Walter Rogers wrogers@gmail.com"
                    />
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Waik Maichales w@gmail.com"
                        title="Waik Maichales w@gmail.com"
                    />
                    </TreeNode>
                    <TreeNode
                        value="Sources"
                        title="Sources"
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                    >
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Facebook Ad Campaign"
                        title="Spring Email newsletter"
                    />
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Spring Email newsletter"
                        title="Spring Email newsletter"
                    />
                    </TreeNode>
                    <TreeNode
                        value="Team"
                        title="Team"
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                    >
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="YL Hacks"
                        title="YL Hacks"
                    />
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="YL Rockstar"
                        title="YL Rockstar"
                    />
                    </TreeNode>
                </TreeNode>
               
                  <TreeNode
                    value="Grow"
                    title="Grow"
                    style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#232333",
                        lineHeight: "20px"
                    }}
                    
                >
                    <TreeNode
                        value="Watch"
                        title="Watch"
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                    >
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Walter Rogers wrogers@gmail.com"
                        title="Walter Rogers wrogers@gmail.com"
                    />
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Waik Maichales w@gmail.com"
                        title="Waik Maichales w@gmail.com"
                    />
                    </TreeNode>
                    <TreeNode
                        value="Manage"
                        title="Manage"
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                    >
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Facebook Ad Campaign"
                        title="Spring Email newsletter"
                    />
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Spring Email newsletter"
                        title="Spring Email newsletter"
                    />
                    </TreeNode>
                    <TreeNode
                        value="Go Live"
                        title="Go Live"
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                    >
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="YL Hacks"
                        title="YL Hacks"
                    />
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="YL Rockstar"
                        title="YL Rockstar"
                    />
                    </TreeNode>
                </TreeNode>
                <TreeNode
                    value="Focus"
                    title="Focus"
                    style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#232333",
                        lineHeight: "20px"
                    }}
                    
                >
                    <TreeNode
                        value="Event"
                        title="Event"
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                    >
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Event1"
                        title="Event1"
                    />
                    <TreeNode
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            color: "#666666",
                        }}
                        value="Event2"
                        title="Event2"
                    />
                    </TreeNode>
                    
                </TreeNode>
                </TreeSelect>
                
            </StyledHeaderSearch>
        </>
    )
}

export default HeaderSearch

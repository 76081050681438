import { Button } from "antd";
import styled from "styled-components";

export const PrimaryButton = styled(Button)`
  border: 1px solid transparent;
  background: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 8px 17px #00000019;
  &:not(&.ant-btn-circle, &.ant-btn-round) {
    border-radius: 5px;
    padding: 15px;
    height: 48px;
    min-width: ${({ width }) => width || "120px"};
    ${({ margin }) => (margin ? { margin } : {})}
  }
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  &.ant-btn-circle {
    height: ${({ height }) => height || "48px"};
    width: ${({ width }) => width || "48px"};
  }
`;

export const SecondaryButton = styled(Button)`
  border: 1px solid transparent;
  background: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 8px 17px #00000019;
  &:not(&.ant-btn-circle, &.ant-btn-round) {
    border-radius: 5px;
    padding: 15px;
    height: 48px;
    min-width: ${({ width }) => width || "120px"};
    ${({ margin }) => (margin ? { margin } : {})}
  }
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondary};
  }

  &.ant-btn-circle {
    height: ${({ height }) => height || "48px"};
    width: ${({ width }) => width || "48px"};
  }
`;

import React from "react";
import {
  AntTable,
  DatatableHeader,
  DatatableHeaderLeft,
  DatatableHeaderRight,
} from "./datatable.styles";
import SearchBar from "./SearchBar";
import ItemPerPageSelect from "./ItemPerPageSelect";
import { Col, Row } from "antd";

const FormDataTable = ({
  search = true,
  showItemPerPage = true,
  headerContentLeft,
  headerContentRight,
  onSearch = console.log,
  onChangeItemPerPage = console.log,
  ...props
}) => {
  return (
    <>
      <AntTable
        size="small"
        scroll={{ x: 768 }}
        {...props}
        style={{ padding: "10px" }}
      />
    </>
  );
};

export default FormDataTable;
import { Popover } from "antd";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";
export const ColorPicker = ({
  title,
  color,
  onChangeComplete,
  size = 56,
  ...props
}) => {
  const [show, setShow] = useState(false);
  return (
    <ColorPickerContainer>
      <Popover
        name="primary"
        trigger="click"
        {...props}
        content={
          <SketchPicker color={color} onChangeComplete={onChangeComplete} />
        }
        title={title}
        visible={show}
        onVisibleChange={() => setShow((s) => !s)}
      >
        <img
          src="/images/color-picker.png"
          height={size}
          width={size}
          alt="color"
        />
      </Popover>
      <ColorPreview color={color} />
      <ColorText>{color}</ColorText>
    </ColorPickerContainer>
  );
};

const ColorPreview = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ color }) => color || "#ffffff"};
  border-radius: 50%;
  margin: 15px;
`;

const ColorText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #232333;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

import React, { useEffect, useState } from "react";
import { Avatar, Dropdown } from "antd";
import { Link, useHistory } from "react-router-dom";
import { adminRoutes, authRoutes } from "../../../helpers/adminRoutes";
// import { FiSearch } from "react-icons/fi";
import {
  StyledHeaderTop,
  StyledLeftSideMenu,
  StyledRightSideMenu,
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
} from "./TheHeader.styles";
import { CgMenu } from "react-icons/cg";
import { HiOutlineChevronDown } from "react-icons/hi";
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import { NotificationSvg, ManagePasswordSvg } from "../../../assets/svgs";
import HeaderSearch from "./HeaderSearch";
import HeaderNotification from "./HeaderNotification";
import { useDispatch, useSelector } from "react-redux";
import { logout, me } from "./../../../store/reducers/user.reducer"
import { getInitials, imagePath } from "../../../utils/fn";
import { useTheme } from "styled-components";
import { UserAvatar } from "../../../ui/Avatar";

const NotificationSettingIcon = (props) => {
  return <NotificationSvg {...props} />;
};

const ManagePasswordIcon = (props) => {
  return <ManagePasswordSvg {...props} />;
};

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { user } = useSelector((state) => state.user);
  const handleLogout = () => {
    dispatch(logout());
    history.push(adminRoutes.MANAGEPASSWORD);
  };
  const handlePassword = () => {
    history.push(adminRoutes.MANAGEPASSWORD);
  };

  const [openSideBar, setopenSider] = useState(true);

  useEffect(() => {
    // Fecth user details and update store, if token but store is empty
    dispatch(me());
  }, [dispatch]);

  const menu = (
    <StyledHeaderDropdownContentWrappeer>
      {/* <StyledHeaderDropdownMenuItem key="0">
        {user?.firstName} {user?.lastName}
      </StyledHeaderDropdownMenuItem> */}

      <StyledHeaderDropdownMenuItem key="0" icon={<FaUser />}>
        <Link to={adminRoutes.PROFILE} className="nav-link">
          My Profile
        </Link>
      </StyledHeaderDropdownMenuItem>
      <StyledHeaderDropdownMenuItem
        key="1"
        icon={
          <NotificationSettingIcon color="#949494" width="16" height="16" />
        }
      >
        <Link to={adminRoutes.SETTING} className="nav-link">
          Settings
        </Link>
      </StyledHeaderDropdownMenuItem>
      <StyledHeaderDropdownMenuItem
        key="2"
        icon={<ManagePasswordIcon color="#949494" width="16" height="16" />}
      >
        <Link to={adminRoutes.MANAGEPASSWORD} className="nav-link">
          Manage Password
        </Link>
      </StyledHeaderDropdownMenuItem>
      <StyledHeaderDropdownMenuItem key="3" icon={<FaSignOutAlt />}>
        <Link to="" onClick={handleLogout} className="nav-link">
          Sign Out
        </Link>
      </StyledHeaderDropdownMenuItem>
    </StyledHeaderDropdownContentWrappeer>
  );

  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSider");
    if (x.length > 0) {
      document.body.classList.remove("openSider");
      console.log("remove");
      setopenSider(true);
    } else {
      document.body.classList.add("openSider");
      console.log("add");
      setopenSider(false);
    }
  };

  const avatarProps = {
    src: imagePath(user?.avatar?.path),
    ...(user?.avatar?.path
      ? { src: imagePath(user?.avatar?.path) }
      : {
          fullName: `${user?.firstName || ""} ${user?.lastName || ""}`,
        }),
  };

  return (
    <>
      <StyledHeaderTop openSideBar={openSideBar} className="sticky-header">
        <StyledLeftSideMenu>
          <span>
            <CgMenu className="header_hamburger_menu" onClick={openSider} />
          </span>
          <HeaderSearch />
        </StyledLeftSideMenu>
        <StyledRightSideMenu>
          {/* <img src="/images/waving-hand.png" className="hand_icon" alt="" /> */}
          {user && (
            <div className="user_profile_wrapper">
              {/* <Avatar {...avatarProps} /> */}
              <UserAvatar {...avatarProps} />
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
                overlayClassName="header_dropdown"
              >
                <a href onClick={(e) => e.preventDefault()}>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                  <HiOutlineChevronDown className="header_dropdown_menu_icon" />
                </a>
              </Dropdown>
            </div>
          )}
          {user && (
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="header_dropdown"
            >
              {/* <Avatar {...avatarProps} className="header_user_mobile_image" /> */}
              <UserAvatar
                {...avatarProps}
                className="header_user_mobile_image"
              />
            </Dropdown>
          )}
          <HeaderNotification />
        </StyledRightSideMenu>
      </StyledHeaderTop>
    </>
  );
};

export default Header;

// App.js
import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { hexToRgb, rgbaToHexify } from "../src/utils/fn";
import {store} from "./store"
import ResetPassword from "./Components/ResetPassword";
import { AuthLayout } from "./containers/AuthLayout";
import { adminRoutes } from "./helpers/adminRoutes";
import { ThemeProvider } from "styled-components";
import { Layout } from "./containers/TheLayout";
import { Provider } from "react-redux";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">Loading...</div>
  </div>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/auth/login" />
      )
    }
  />
);

const theme = {
  colors: {
    primary: "#02162F",
    primary2: rgbaToHexify(`rgba(${hexToRgb("#02162F").join(", ")}, 0.75)`),
    secondary: "#9BD4CE",
    yellow: "#f2d53c",
    red: "#c80e13",
  },
  fonts: {
    body: "Arial, Helvetica, sans-serif",
  },
};

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              
              <Route path="/auth" component={AuthLayout} />
               <PrivateRoute path="/" component={Layout} />
            </Switch>
          </React.Suspense>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;

import { Switch } from "antd";
import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  padding-top: 10px;
`;

export const SwitchWrapper = styled(Switch)`
  margin-left: 30px;
  margin-right: 20px;
`;

export const FieldWrapper = styled.div`
  color: #747487
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

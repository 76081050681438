import React from "react";
import { FiSearch } from "react-icons/fi";
import { Search } from "./datatable.styles";

const SearchBar = (props) => {
  return (
    <Search placeholder="Type to filter..." prefix={<FiSearch />} {...props} />
  );
};

export default SearchBar;

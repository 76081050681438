import { Breadcrumb, Button, Table } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { adminRoutes } from "../helpers/adminRoutes";

const Permission = () => {
  const location = useLocation();
  const history = useHistory();

  const [viewState, viewSetState] = useState([]);
  const [editState, editSetState] = useState([]);
  const [deleteState, deleteSetState] = useState([]);

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  const handleDeletePermission = (e) => {
    let data = e;
    let oldData = deleteState;
    oldData.push(data);
    deleteSetState(oldData);
  };

  const handleEditPermission = (e) => {
    let data = e;
    let oldData = editState;
    oldData.push(data);
    editSetState(oldData);
  };

  const handleViewPermission = (e, record) => {
    let data = e;
    let oldData = viewState;
    oldData.push(data);
    viewSetState(oldData);
  };

  const handleSubmit = () => {
    console.log("editState", editState);
    console.log("viewState", viewState);
    console.log("deleteState", deleteState);
    history.push(adminRoutes.DASHBOARD);
  };

  const dataSource = [
    {
      key: "1",
      name: "Select All",
    },
    {
      key: "2",
      name: "Dashboard",
    },
    {
      key: "3",
      name: "Leads",
    },
    {
      key: "4",
      name: "Grow",
    },
  ];

  const columns = [
    {
      title: "Page Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "View",
      width: "100px",
      dataIndex: "view",
      key: "view",
      render: (_, record) => {
        return (
          <span>
            <Checkbox
              onChange={(e) => handleViewPermission(e, record)}
            ></Checkbox>
          </span>
        );
      },
    },
    {
      title: "Edit",
      width: "100px",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => {
        return (
          <span>
            <Checkbox onChange={() => handleEditPermission(record)}></Checkbox>
          </span>
        );
      },
    },
    {
      title: "Delete",
      width: "100px",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => {
        return (
          <span>
            <Checkbox
              onChange={() => handleDeletePermission(record)}
            ></Checkbox>
          </span>
        );
      },
    },
  ];
  return (
    <div>
      <div className="breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Invite Client</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="content-container">
        <div className="content-title">Manage Permissions</div>
        <div className="content-sub-title">Julianne McElroy</div>

        <Table
          className="custom-table permission-table"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />

        <Button className="custom-btn" type="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Permission;

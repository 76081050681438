import React, { useState, useCallback, useEffect } from "react";
import { Col, Form, message, Row, Tabs, Button, Input, Modal } from "antd";
import { PrimaryButton } from "../../ui/Button";
import { FormInput, FormItem } from "../../ui/Form/Input";
import { ColorPicker } from "../../ui/Form/ColorPicker";
import { FileUpload } from "../../ui/Form/FileUpload";
import { Container } from "../../ui/Layout/Container";
import { Card } from "../../ui/Card";
import { IoIosClose } from "react-icons/io";

import {
  StyledButton,
  StyledTabButtonWrap,
  StyledFormButton,
  StyledFormItem,
  StyledFormControl,
  StyledContentTitle,
  // StyledPrimaryButton,
} from "./style";
import { FormDataTable } from "../../ui/Datatable";
import { ToggleSwitch } from "../../ui/Form";
import { ArrowDownOutlined } from "@ant-design/icons";
import { DownloadSvg } from "../../assets/svgs";
import CLientAPI from "../../api/http/client";
import {
  convertJSONToFormData,
  downloadFile,
  getImageBase64,
  imagePath,
} from "../../utils/fn";
import { useHistory, useParams } from "react-router";
import { adminRoutes } from "../../helpers/adminRoutes";
import { child_process } from "child_process";
import Actions from "../../ui/Datatable/Actions";

const { TabPane } = Tabs;

const InviteClient = () => {
  const [tabstate, setTabState] = React.useState({ key: "Invite Client" });
  const [clientState, clientSetState] = React.useState();
  const [editData, setEditData] = React.useState();
  const [modulePermission, setModulePermission] = React.useState({
    progress: false,
    connect: false,
    grow: false,
    focus: false,
  });

  const [images, setImages] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const history = useHistory();
  const [datas, setData] = useState([
    {
      key: "dashboard",
      name: "Progess",
      status: false,
    },
    {
      key: "connect",
      name: "Connect",
      status: false,
      children: [
        {
          key: "broadcasts",
          name: "Broadcasts",
        },
        {
          key: "relationships",
          name: "Relationships",
        },
        {
          key: "communities",
          name: "Communities",
        },
      ],
    },
    {
      key: "grow",
      name: "Grow",
      status: false,
      children: [
        {
          key: "watchVideos",
          name: "Watch Videos",
        },
        {
          key: "manageVideos",
          name: "Manage Videos",
        },
        {
          key: "goLive",
          name: "Go Live",
        },
      ],
    },
    {
      key: "focus",
      name: "Focus",
      status: false,
      children: [
        {
          key: "myEvents",
          name: "My Events",
        },
        {
          key: "findEvents",
          name: "Find Events",
        },
      ],
    },
  ]);
  const [colors, setColors] = useState({
    primary: "#0061a4",
    secondary: "#f8c319",
    third: "#3798db",
  });
  const [addedData, setAddedData] = useState();
  const [landingPageLogo, setlandinPageLogo] = useState();
  const [landingPageBackground, setlandingPageBackground] = useState();
  const [homePageBackground, sethomePageBackground] = useState();
  const [faviconImage, setfaviconImage] = useState();
  const [userDetails, setUserDetails] = useState("");
  const [inputValue, setInputValue] = React.useState({
    frontEndWeb: "",
    frontEndMobile: "",
    api: "",
    database: "",
  });
  const params = useParams();
  const [form] = Form.useForm();

  function callback(key) {
    setTabState({ key: key });
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const getUser = useCallback((id) => {
    CLientAPI.getClientListDetail(id)
      .then(({ data }) => {
        form.setFieldsValue({
          name: data?.name,
          clientName: data?.clientName,
          email: data?.email,
        });

        setColors({
          primary: data?.primary,
          secondary: data?.secondary,
          third: data?.third,
        });

        setData(data?.module);
        //   setModulePermission({
        //     progress:data?.progress,
        //     connect:data?.connect,
        //     grow:data?.grow,
        //     focus:data?.focus
        //   })

        if (data?.faviconImage) {
          setfaviconImage((s) => ({
            ...s,
            image: {
              ...data.faviconImage,
              name: data.faviconImage?.originalName,
              url: imagePath(data.faviconImage.path),
            },
            image_src: imagePath(data.faviconImage.path),
          }));
        }

        if (data?.landingPageBackground) {
          setlandingPageBackground((s) => ({
            ...s,
            image: {
              ...data.landingPageBackground,
              name: data.landingPageBackground?.originalName,
              url: imagePath(data.landingPageBackground.path),
            },
            image_src: imagePath(data.landingPageBackground.path),
          }));
        }

        if (data?.landingPageLogo) {
          setlandinPageLogo((s) => ({
            ...s,
            image: {
              ...data.landingPageLogo,
              name: data.landingPageLogo?.originalName,
              url: imagePath(data.landingPageLogo.path),
            },
            image_src: imagePath(data.landingPageLogo.path),
          }));
        }

        if (data?.homePageBackground) {
          sethomePageBackground((s) => ({
            ...s,
            image: {
              ...data.homePageBackground,
              name: data.homePageBackground?.originalName,
              url: imagePath(data.homePageBackground.path),
            },
            image_src: imagePath(data.homePageBackground.path),
          }));
        }

        setInputValue({
          frontEndWeb: data?.frontEndWeb,
          frontEndMobile: data?.frontEndMobile,
          api: data?.api,
          database: data?.database,
        });

        setUserDetails(data);
        if (data.faviconImage) {
          setfaviconImage((s) => ({
            ...s,
            image_src: imagePath(data.faviconImage?.path),
          }));
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.id) {
      getUser(params.id);
    }
  }, [getUser, params.id]);

  const handleInput = (e, type) => {
    if (type === "frontEndWeb") {
      setInputValue((p) => ({
        ...p,
        frontEndWeb: e.target.value,
      }));
    } else if (type === "frontEndMobile") {
      setInputValue((p) => ({
        ...p,
        frontEndMobile: e.target.value,
      }));
    } else if (type === "api") {
      setInputValue((p) => ({
        ...p,
        api: e.target.value,
      }));
    } else if (type === "database") {
      setInputValue((p) => ({
        ...p,
        database: e.target.value,
      }));
    }
  };

  const LogoUpload = {
    name: "landingPageLogo",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return false;
      }
      getBase64(file, (base64) =>
        setlandinPageLogo((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setlandinPageLogo({
        image: "",
        image_src: "",
      });
    },
    fileList: landingPageLogo?.image ? [landingPageLogo?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const backgroundImageUpload = {
    name: "landingPageBackground",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return false;
      }
      getBase64(file, (base64) =>
        setlandingPageBackground((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setlandingPageBackground({
        image: "",
        image_src: "",
      });
    },
    fileList: landingPageBackground?.image
      ? [landingPageBackground?.image]
      : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const homePageImageUpload = {
    name: "homePageBackground",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return false;
      }
      getBase64(file, (base64) =>
        sethomePageBackground((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      sethomePageBackground({
        image: "",
        image_src: "",
      });
    },
    fileList: homePageBackground?.image ? [homePageBackground?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const faviconImageUpload = {
    name: "faviconImage",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return false;
      }
      getBase64(file, (base64) =>
        setfaviconImage((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setfaviconImage({
        image: "",
        image_src: "",
      });
    },
    fileList: faviconImage?.image ? [faviconImage?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onhandleEdit = (key, item) => {
    setVisible(true);
    setEditData(item);
  };

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        onhandleEdit(key, item);
        break;

      default:
        break;
    }
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
  ];

  const columns = [
    {
      title: "Module Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (_, record, index) => {
        return (
          ["dashboard", "connect", "grow", "focus"].includes(record.key) && (
            <ToggleSwitch
              defaultChecked={_}
              onChange={(e) => handleStatus(e, record)}
            />
          )
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          ["dashboard", "connect", "grow", "focus"].includes(record.key) && (
            <Actions
              options={actionOptions}
              item={record}
              onChange={actionHandler}
            />
          )
        );
      },
    },
  ];

  const handleStatus = (e, data, i) => {
    let permissinData = datas;
    let objIndex = permissinData?.findIndex((x) => x.name == data.name);
    permissinData[objIndex].status = e;
    setData(permissinData);
  };

  const handleClick = (values) => {
    let data = { ...inputValue, ...clientState, ...colors };
    data.module = datas;
    // const formdata = new FormData();

    //  for (let key in data) {
    //    if (Array.isArray(data[key])) {
    //      data[key].forEach((ele, index) => {
    //        const tempFormKey = `${key}[${index}]`;
    //        formdata.append(`${tempFormKey}`, ele);
    //      });
    //    } else if (typeof data[key] === "object") {
    //      for (let subKey in data[key]) {
    //        formdata.append(`${key}.${subKey}`, data[key][subKey]);
    //      }
    //    } else {
    //      if (data[key]) formdata.append(key, data[key]);
    //    }
    //  }

    const formdata = convertJSONToFormData(data);

    if (landingPageLogo?.image && !landingPageLogo.image?.url) {
      formdata.append("landingPageLogo", landingPageLogo?.image);
    }

    if (landingPageBackground?.image && !landingPageBackground.image?.url) {
      formdata.append("landingPageBackground", landingPageBackground?.image);
    }
    if (homePageBackground?.image && !homePageBackground.image?.url) {
      formdata.append("homePageBackground", homePageBackground?.image);
    }
    if (faviconImage?.image && !faviconImage.image?.url) {
      formdata.append("faviconImage", faviconImage?.image);
    }
    if (params.id) {
      CLientAPI.updateClient(params.id, formdata).then((data) => {
        setAddedData(data);
      });
    } else {
      CLientAPI.addClient(formdata).then((data) => {
        setAddedData(data);
      });
    }

    setTabState({ key: "Create Deployment" });
  };

  const onFinished = (values) => {
    history.push(adminRoutes.DASHBOARD);
  };
  const onModalSubmit = (values) => {
    let dta = [...datas];
    let key =
      editData.key == "dashboard"
        ? 0
        : editData.key == "connect"
        ? 1
        : editData.key == "grow"
        ? 2
        : 3;
    dta[key] = { ...editData };
    setData(dta);
    setVisible(false);
  };

  const handleDatabase = () => {
    console.log("params.id>>>",params.id)
    CLientAPI.getClientListDetail(addedData?.data?._id ?addedData?.data?._id:params.id).then((data) => {
      console.log("data>>>",data)
      // downloadFile(
      //   process.env.REACT_APP_API_URL + "/public/uploads/"+ addedData?.data?.name +".zip",
      //   "application/zip",
      //   "database.zip"
      // );
       window.open("http://147.182.184.161/"+( data?.data?.name).toLowerCase()+ ".zip", "_blank");
    });
  };

  const handleAPIBuild = () => {
    // CLientAPI.getClientListDetail(addedData?.data?._id).then((data) => {
    //   downloadFile(
        
    //  "http://147.182.184.161/backend.zip",
    //     "application/zip",
    //     "backend.zip"
    //   );
    // });
    console.log(">>>>> clicked");
    window.open("https://vivo.xeromedia.com/auth/login", "_blank");
  };

  const handleFrontendWebBuild = () => {
    // CLientAPI.getClientListDetail(addedData?.data?._id).then((data) => {
      window.open("http://147.182.184.161/frontend.zip", "_blank");
    // });
  };
  const onFinish = (values) => {
    let img = [];
    img.push({ landingPageLogo: landingPageLogo?.image });
    img.push({ landingPageBackground: landingPageBackground?.image });
    img.push({ homePageBackground: homePageBackground?.image });
    img.push({ faviconImage: faviconImage?.image });

    //  let obj={
    //    landingPageLogo:landingPageLogo?.image,
    //    landingPageBackground:landingPageBackground?.image,
    //    homePageBackground:homePageBackground?.image,
    //    faviconImage:faviconImage?.image,
    //  }

    setImages(img);
    clientSetState(values);
    setTabState({ key: "Manage Modules" });
  };

  const handleParentChange = (e, item, type) => {
    if (item.key && type == "child") {
      let child = [...editData.children];
      let innerChild = [...child[2].children];
      console.log("mansi innerchild", innerChild);
      if (item.key === "find-a-team") {
        innerChild[0] = { ...innerChild[0], name: e.target.value };
      } else if (item.key === "create-a-team") {
        innerChild[1] = { ...innerChild[1], name: e.target.value };
      }

      console.log("mansi innerChild", innerChild);
      child[2].children = innerChild;
      console.log("mansi child", child);
      setEditData((p) => ({
        ...p,
        children: child,
      }));
    } else if (item.key) {
      console.log("mansi editData in parent", editData);
      let objIndex = editData.children.findIndex((x) => x.key == item.key);
      let child = [...editData.children];
      console.log("mansi child", child);
      child[objIndex] = { ...item, name: e.target.value };

      console.log("child", child);
      setEditData((p) => ({
        ...p,
        children: child,
      }));
    } else {
      setEditData((p) => ({
        ...p,
        name: e.target.value,
      }));
    }
  };

  console.log("editData", editData);

  return (
    <Container>
      <StyledTabButtonWrap>
        <Tabs onChange={params.id ? callback : ""} activeKey={tabstate.key}>
          <TabPane tab="Invite Client" key="Invite Client"></TabPane>
          <TabPane tab="Manage Modules" key="Manage Modules"></TabPane>
          <TabPane tab="Create Deployment" key="Create Deployment"></TabPane>
        </Tabs>
      </StyledTabButtonWrap>
      <Card>
        {tabstate.key == "Invite Client" && (
          <Form
            form={form}
            layout="vertical"
            onFinish={(e) => onFinish(e)}
            requiredMark={false}
          >
            <Row gutter={30} style={{ maxWidth: "852px" }}>
              <Col sm={24} md={12} lg={12}>
                <FormItem
                  label="Client Name"
                  name="clientName"
                  type="text"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormInput placeholder="Enter client name" />
                </FormItem>
              </Col>
              <Col sm={24} md={12} lg={12}>
                {" "}
                <FormItem
                  label="Email"
                  name="email"
                  type="text"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormInput placeholder="Enter email address" />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              label="Client Business Name"
              name="name"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput placeholder="Enter the client business name" />
            </FormItem>
            <Row gutter={4} style={{ maxWidth: "882px" }}>
              <Col sm={24} md={12} lg={8} xl={8} xxl={8}>
                <FormItem label="Primary Brand Color">
                  <ColorPicker
                    name="primary"
                    title="Primary Brand Color"
                    color={colors.primary}
                    onChangeComplete={({ hex }) =>
                      setColors((c) => ({ ...c, primary: hex }))
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} md={12} lg={8} xl={8} xxl={8}>
                <FormItem label="Secondary Brand Color">
                  <ColorPicker
                    name="secondary"
                    title="Secondary Brand Color"
                    color={colors.secondary}
                    onChangeComplete={({ hex }) =>
                      setColors((c) => ({ ...c, secondary: hex }))
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} md={12} lg={8} xl={8} xxl={8}>
                <FormItem label="Third Brand Color">
                  <ColorPicker
                    name="third"
                    title="Third Brand Color"
                    color={colors.third}
                    onChangeComplete={({ hex }) =>
                      setColors((c) => ({ ...c, third: hex }))
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem label="Landing Page Logo">
              <FileUpload {...LogoUpload} />
            </FormItem>
            <FormItem label="Landing Page Background">
              <FileUpload {...backgroundImageUpload} />
            </FormItem>
            <FormItem label="Homepage Logo">
              <FileUpload {...homePageImageUpload} />
            </FormItem>
            <FormItem label="Favicon Image">
              <FileUpload {...faviconImageUpload} />
            </FormItem>
            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Next
              </PrimaryButton>
            </FormItem>
          </Form>
        )}

        {tabstate.key === "Manage Modules" && (
          <Form>
            <FormDataTable
              columns={columns}
              dataSource={datas}
              pagination={false}
              showItemPerPage={false}
              search={false}
            />
            <FormItem>
              <PrimaryButton
                margin="15px 0 0 0"
                type="button"
                onClick={handleClick}
              >
                Next
              </PrimaryButton>
            </FormItem>
          </Form>
        )}
        {tabstate.key === "Create Deployment" && (
          <>
            <Form
              layout="vertical"
              onFinish={(e) => onFinished(e)}
              requiredMark={false}
            >
              <FormItem
                label="Front End Web"
                name="frontEndWeb"
                type="text"
                rules={[
                  {
                    required: false,
                    message: "This is required",
                  },
                ]}
              >
                {console.log(inputValue["frontEndWeb"])}
                <FormInput
                  onChange={(e) => handleInput(e, "frontEndWeb")}
                  value={inputValue["frontEndWeb"]}
                  placeholder="Front end web"
                  suffix={
                    <StyledButton
                      shape="circle"
                      icon={<DownloadSvg />}
                      onClick={handleFrontendWebBuild}
                    ></StyledButton>
                  }
                />
              </FormItem>
              <FormItem
                label="Front End Mobile"
                name="frontEndMobile"
                type="text"
                rules={[
                  {
                    required: false,
                    message: "This is required",
                  },
                ]}
              >
                {console.log(inputValue["frontEndMobile"])}
                <FormInput
                  // onChange={(e) => handleInput(e, "frontEndMobile")}
                  // value={inputValue["frontEndMobile"]}
                  placeholder="Front end mobile"
                  suffix={
                    <StyledButton
                      shape="circle"
                      icon={<DownloadSvg />}
                      onClick={()=>handleFrontendWebBuild()}
                    ></StyledButton>
                  }
                />
              </FormItem>
              <FormItem
                label="API"
                name="api"
                type="text"
                rules={[
                  {
                    required: false,
                    message: "This is required",
                  },
                ]}
              >
                {console.log(inputValue["api"])}

                <FormInput
                  onChange={(e) => handleInput(e, "api")}
                  value={inputValue["api"]}
                  placeholder="API"
                  suffix={
                    <StyledButton
                      shape="circle"
                      icon={<DownloadSvg />}
                      onClick={()=>handleAPIBuild()}
                    ></StyledButton>
                  }
                />
              </FormItem>
              <FormItem
                label="Database"
                name="database"
                type="text"
                rules={[
                  {
                    required: false,
                    message: "This is required",
                  },
                ]}
              >
                {console.log(inputValue["database"])}

                <FormInput
                  onChange={(e) => handleInput(e, "database")}
                  value={inputValue["database"]}
                  placeholder="Database"
                  suffix={
                    <StyledButton
                      shape="circle"
                      icon={<DownloadSvg />}
                      onClick={handleDatabase}
                    ></StyledButton>
                  }
                />
              </FormItem>
              <FormItem>
                <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                  {params.id ? "Update" : "Save"}
                </PrimaryButton>
              </FormItem>
            </Form>
            {/* <StyledFormButton>
              <StyledPrimaryButton>
                <PrimaryButton>Email to Client</PrimaryButton>
              </StyledPrimaryButton>
              <StyledPrimaryButton>
                <PrimaryButton>Download Locally</PrimaryButton>
              </StyledPrimaryButton>
            </StyledFormButton> */}
          </>
        )}
        {visible && (
          <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            // width={"960px"}
            footer={null}
            onOk={form.submit}
            style={{ borderRadius: "5px" }}
            className="modal_wrapper"
            closeIcon={<IoIosClose className="modal_close_icon" />}
            maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
            focusTriggerAfterClose={false}
          >
            <StyledFormControl>
              <Row gutter={30} style={{ maxWidth: "852px" }}>
                {editData && (
                  <Form initialValues={{ [editData.key]: editData.name }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <FormItem
                        className="custom-textbox"
                        name={editData.key}
                        label={"Parent"}
                      >
                        <FormInput
                          maxWidth={"290px"}
                          type="text"
                          onChange={(e) =>
                            setEditData((p) => ({
                              ...p,
                              name: e.target.value,
                            }))
                          }
                        />
                      </FormItem>
                    </Col>
                  </Form>
                )}
                {editData &&
                  editData?.children &&
                  editData.children.map((item, i) => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        key={item.key}
                      >
                        <StyledFormItem>
                          <Form
                            initialValues={{
                              [item.key]: item.name,
                            }}
                          >
                            <Form.Item
                              className="custom-textbox"
                              name={item.key}
                              // label="First Name"
                            >
                              <Input
                                type="text"
                                onChange={(e) => handleParentChange(e, item)}
                              />
                            </Form.Item>
                          </Form>
                        </StyledFormItem>
                        {item &&
                          item?.children &&
                          item?.children.map((item, i) => {
                            return (
                              <StyledFormItem style={{ marginLeft: "100px" }}>
                                <Form
                                  initialValues={{
                                    [item.key]: item.name,
                                  }}
                                >
                                  <Form.Item
                                    className="custom-textbox"
                                    name={item.key}
                                    // label="First Name"
                                  >
                                    <Input
                                      type="text"
                                      onChange={(e) =>
                                        handleParentChange(e, item, "child")
                                      }
                                    />
                                  </Form.Item>
                                </Form>
                              </StyledFormItem>
                            );
                          })}
                      </Col>
                    );
                  })}

                <PrimaryButton
                  style={{ marginLeft: "130px" }}
                  htmlType="submit"
                  onClick={() => onModalSubmit()}
                >
                  Save
                </PrimaryButton>
                {/* </Form> */}
              </Row>
            </StyledFormControl>
          </Modal>
        )}
      </Card>
    </Container>
  );
};

export default InviteClient;

import { Dropdown } from "antd";
import React, { useState } from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

import {
  ItemPerPageContainer,
  ItemPerPageText,
  ItemPerPageTrigger,
  OptionMenu,
  OptionMenuItem,
  Label,
} from "./datatable.styles";

const ItemPerPageSelect = ({
  label,
  options,
  onChange,
  defaultPageSize = 20,
  backgrounds = "#f6f7f9",
  borderRadius,
  boxShadow,
  height,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const pageSizeChangehandler = (value) => {
    console.log({ value });
    setIsOpen(false);
    setPageSize(value);
    onChange(value);
  };

  return (
    <ItemPerPageContainer>
      {label && <Label style={{ alignItems: "center" }}>{label}:</Label>}
      <Dropdown
        overlay={
          <OptionMenuList
            options={options}
            pageSize={pageSize}
            onClick={pageSizeChangehandler}
          />
        }
        visible={isOpen}
        onVisibleChange={setIsOpen}
        trigger="click"
      >
        <ItemPerPageTrigger
          boxShadow={boxShadow}
          backgrounds={backgrounds}
          borderRadius={borderRadius}
          height={height}
        >
          <ItemPerPageText>{pageSize}</ItemPerPageText>
          {isOpen ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
        </ItemPerPageTrigger>
      </Dropdown>
    </ItemPerPageContainer>
  );
};

const OptionMenuList = ({ options = [5, 10, 20, 50], pageSize, onClick }) => {
  return (
    <OptionMenu onClick={({ key }) => onClick(key)} selectedKeys={[pageSize]}>
      {options.map((key) => (
        <OptionMenuItem key={key}>{key}</OptionMenuItem>
      ))}
    </OptionMenu>
  );
};

export default ItemPerPageSelect;

import React from "react";
import styled from "styled-components";

import PlusIconButton from "../../ui/Button/PlusIconButton";
import FloatButton from "../../ui/Button/FloatButton";
import { Flex } from "../../ui/Layout/Flex";

export const Heading = ({ children, ...props }) => {
  return <Text {...props}>{children}</Text>;
};

export const HeadingWithIconButton = ({ buttonProps, ...props }) => {
  return (
    <Flex>
      <PlusIconButton {...buttonProps} />
      <Heading marginBottom="0px" marginLeft="14px" {...props} />
      <FloatButton {...buttonProps} />
    </Flex>
  );
};

export const CardHeading = ({ children }) => {
  return <CardHeadingText>{children}</CardHeadingText>;
};

export const CardSubHeading = ({ children }) => {
  return <CardSubHeadingText>{children}</CardSubHeadingText>;
};

export const CardSubHeadingSpan = ({ children }) => {
  return <CardSubHeadingTextSpan>{children}</CardSubHeadingTextSpan>;
};
export const HeadingWithSubTitle = ({ heading, subtitle }) => {
  return (
    <div>
      <HeadingText>{heading}</HeadingText>
      <HeadingText fontWeight={400} lineHeight={"18px"} color={"#747487"}>
        {subtitle}
      </HeadingText>
    </div>
  );
};

const Text = styled.span`
  display: flex;
  font-weight: bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "24px")};
  line-height: 24px;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "17px"};
  color: ${({ color }) => (color ? color : "black")};

  @media (max-width: 991.98px) {
    margin-bottom: ${({ marginBottom }) =>
      marginBottom ? marginBottom : "15px"};
  }
  ${({ children, ...props }) => props}
`;

const CardHeadingText = styled.h3`
  display: inline-block;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #232333;
  margin-right: 30px;
  white-space: nowrap;

  @media (max-width: 1399.98px) {
    font-size: 18px;
    line-height: 23px;
  }

  @media (max-width: 767.98px) {
    font-size: 14px;
  }

  @media (max-width: 575.98px) {
    font-size: 18px;
  }
`;

const CardSubHeadingText = styled.h6`
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  color: #747487;
  white-space: nowrap;

  @media (max-width: 1399.98px) {
    font-size: 14px;
  }

  @media (max-width: 767.98px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`;

const CardSubHeadingTextSpan = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #747487;
  margin-left: 10px;
  white-space: nowrap;

  @media (max-width: 1399.98px) {
    font-size: 14px;
  }

  @media (max-width: 767.98px) {
    font-size: 12px;
    margin-left: 5px;
    line-height: 14px;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`;

const HeadingText = styled.div`
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  line-height: ${({ lineHeight }) => lineHeight || "24px"};
  color: ${({ color }) => color || "#232333"};
`;

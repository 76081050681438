import React, { useState } from "react";
import { Menu, Dropdown, Badge, Popover, List, Avatar, Skeleton, Button } from "antd";
import { StyledListWrapper, StyledListItem, StyledNotificationWrapper, StyledNotificationBadge } from './TheHeader.styles';
import { data } from "./HeaderNotificationData";
import { NotificationBellSvg } from "../../../assets/svgs";
import { BsThreeDotsVertical } from "react-icons/bs";
import './style.css';


const NotificationBellIcon = (props) => {
  return (
    <NotificationBellSvg {...props} />
  );
};


const HeaderNotification = () => {

    const [list, setList] = useState(data);
    const loadMore =
       (
        <div className="header_view_more_wrap">
          <Button>View More</Button>
        </div>
    )

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <a href="https://www.antgroup.com">Mark as read</a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href="https://www.aliyun.com">Remove notification</a>
        </Menu.Item>
      </Menu>
    );

    const content = (
      <>
        <StyledListWrapper
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={list}
          renderItem={item => (
            <StyledListItem
              actions={
                        [<Dropdown overlay={menu} trigger={['click']}>
                          <BsThreeDotsVertical className="header_notification_option_button" onClick={e => e.preventDefault()} />
                        </Dropdown>]
                      }
              notificationRead={item.read}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  title={<a href="https://ant.design">{item.name}</a>}
                  description={item.text}
                />
                <span className="header_notification_time">{item.time}</span>
              </Skeleton>
            </StyledListItem>
          )}
        />
      </>
    );


    return (
        <>
            <StyledNotificationWrapper>
                <Popover
                  placement="bottomRight"
                  content={content}
                  trigger="click"
                  overlayClassName="header_popover_wrap"
                >
                <StyledNotificationBadge count={5} size={"small"}>
                    <NotificationBellIcon className="bell_icon" width="26" height="26" />
                </StyledNotificationBadge>
                </Popover>
            </StyledNotificationWrapper>
        </>
    )
}

export default HeaderNotification;

import React from "react";

const DownloadSvg = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.0625 0C4.73438 0 4.5 0.257812 4.5 0.5625V4.5H2.4375C2.01562 4.5 1.80469 5.01562 2.10938 5.32031L5.67188 8.88281C5.83594 9.04688 6.14062 9.04688 6.30469 8.88281L9.86719 5.32031C10.1719 5.01562 9.96094 4.5 9.53906 4.5H7.5V0.5625C7.5 0.257812 7.24219 0 6.9375 0H5.0625ZM12 8.8125C12 8.50781 11.7422 8.25 11.4375 8.25H7.99219L6.84375 9.39844C6.375 9.89062 5.60156 9.89062 5.13281 9.39844L3.98438 8.25H0.5625C0.234375 8.25 0 8.50781 0 8.8125V11.4375C0 11.7656 0.234375 12 0.5625 12H11.4375C11.7422 12 12 11.7656 12 11.4375V8.8125ZM9.09375 10.875C9.09375 11.1328 8.88281 11.3438 8.625 11.3438C8.36719 11.3438 8.15625 11.1328 8.15625 10.875C8.15625 10.6172 8.36719 10.4062 8.625 10.4062C8.88281 10.4062 9.09375 10.6172 9.09375 10.875ZM10.5938 10.875C10.5938 11.1328 10.3828 11.3438 10.125 11.3438C9.86719 11.3438 9.65625 11.1328 9.65625 10.875C9.65625 10.6172 9.86719 10.4062 10.125 10.4062C10.3828 10.4062 10.5938 10.6172 10.5938 10.875Z"
        fill="white"
      />
    </svg>
  );
};

export default DownloadSvg;

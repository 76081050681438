import { DatePicker, TimePicker } from "antd";
import styled from "styled-components";
import locale from "antd/es/date-picker/locale/en_US";

export const FormDatePicker = (props) => {
  const onChange = (v) => {
    console.log(v);
    props.onChange(v);
  };
  return (
    <StyledDatePicker
      dropdownClassName="d-picker"
      format="MM/DD/YYYY"
      dateRender={(c, t) => {
        return (
          <DatePickerCellInner
            isToday={c.isSame(t, "d")}
            className="ant-picker-cell-inner"
          >
            {c.date()}
          </DatePickerCellInner>
        );
      }}
      onChange={onChange}
      {...props}
    />
  );
};
export const FormTimePicker = (props) => {
  return (
    <StyledTimePicker
      popupClassName="t-picker"
      use12Hours
      minuteStep={15}
      format="hh:mm A"
      locale={{
        ...locale,
        lang: {
          ...locale.lang,
          ok: "Save",
        },
      }}
      {...props}
    />
  );
};

const DatePickerCellInner = styled.div(({ isToday, theme }) =>
  isToday
    ? {
        ":before": { border: `1px solid ${theme.colors.secondary} !important` },
      }
    : {}
);

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    &.ant-picker-focused {
      border-color: ${({ theme }) => theme.colors.primary};
    }
    width: 100%;
    max-width: 822px;
    background: #f6f7f9;
    color: #747487;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    min-height: 48px;
    .ant-picker-input {
      .ant-picker-suffix {
        color: ${({ theme }) => theme.colors.primary};
      }
      &:hover {
        border-color: #e5e5e5;
      }
      &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}23;
      }
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  &.ant-picker {
    &.ant-picker-focused {
      border-color: ${({ theme }) => theme.colors.primary};
    }
    width: 100%;
    max-width: 822px;
    background: #f6f7f9;
    color: #747487;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    min-height: 48px;
    .ant-picker-input {
      .ant-picker-suffix {
        color: ${({ theme }) => theme.colors.primary};
      }
      &:hover {
        border-color: #e5e5e5;
      }
      &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}23;
      }
    }
  }
`;

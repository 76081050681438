import { Button } from "antd";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { authRoutes } from "../../helpers/adminRoutes";
import {
  StyledContainer,
  StyledLeftContainer,
  StyledLoginSignButtonWrapper,
  StyledLogoLabel,
  StyledRightContainer,
} from "./AuthLayout.styles";
import AuthRoutes from "./AuthRoutes";

const AuthLayout = () => {
  const { pathname } = useLocation();

  return (
    <StyledContainer>
      <StyledLeftContainer backgroundImg="nasa.jpg">
        <StyledLogoLabel>
          <div className="logo-label">
            <div className="label-ant">ANT</div>
            <div className="label-technologies">Technologies</div>
          </div>
        </StyledLogoLabel>
        {/* {[authRoutes.LOGIN, authRoutes.REGISTRATION].includes(pathname) && (
          <StyledLoginSignButtonWrapper>
            <Button
              size="large"
              className={`ant-btn-tab ${
                pathname === authRoutes.LOGIN ? "active" : ""
              }`}
            >
              <Link to={authRoutes.LOGIN}>Login</Link>
            </Button>
            <Button
              size="large"
              className={`ant-btn-tab ${
                pathname === authRoutes.REGISTRATION ? "active" : ""
              }`}
            >
              <Link to={authRoutes.REGISTRATION}>Join</Link>
            </Button>
          </StyledLoginSignButtonWrapper>
        )} */}
      </StyledLeftContainer>
      <StyledRightContainer>
        <AuthRoutes />
      </StyledRightContainer>
    </StyledContainer>
  );
};

export default AuthLayout;

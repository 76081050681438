import React from "react";
import { Button } from "antd";
import { HiPlus } from "react-icons/hi";
import { PrimaryButton, SecondaryButton } from "./";
import styled from "styled-components";

const defaultProps = {
  icon: <HiPlus style={{ fontSize: "34px" }} />,
  shape: "circle",
};

const createButton = (type, props) => {
  switch (type) {
    case "primary":
      return React.createElement(PrimaryButton, props);

    case "secondary":
      return React.createElement(SecondaryButton, props);

    default:
      return React.createElement(Button, props);
  }
};

const IconButton = ({ type, ...props }) =>
  createButton(type, { ...defaultProps, ...props });

const PlusIconButton = ({ type, ...props }) => {
  return <StyledIconButton type={type} {...props} />;
};

const StyledIconButton = styled(IconButton)`
  @media (max-width: 991px) {
    display: none;
  }
`;

export default PlusIconButton;

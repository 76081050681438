import { Switch } from "antd";
import styled from "styled-components";

export const ToggleSwitch = styled(Switch)`
  &.ant-switch {
    width: 40px;
    height: 16px;
    background: #ebebeb;
    border-radius: 10px;
    .ant-switch-handle {
      width: 20px;
      height: 20px;
      top: -2px;
      left: 0px;

      &:before {
        background: linear-gradient(98.7deg, #d3d6dd 9.29%, #a0a5af 89.16%);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
      }
    }
    &.ant-switch-checked {
      .ant-switch-handle {
        left: calc(100% - 18px - 2px);
        &:before {
          background: linear-gradient(
            276.7deg,
            ${({ theme }) => theme.colors.primary} 0%,
            ${({ theme }) => theme.colors.primary2} 100%
          );
        }
      }
    }
  }
`;

import React from "react";

const notificationBell = ({
    size = "24",
    color = "#ffffff",
    width = "24",
    height = "24",
    ...props
  }) => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 400 400"  width={width} height={height} fill={color} {...props}>
        <g>
            <path class="st0" d="M204.9,8c4.6,1.8,9.6,3,13.7,5.5c12.4,7.6,20.5,25,11.8,43.7c1.2,0.5,2.5,1.1,3.7,1.5
                c40.2,14.4,67,52,67.3,94.7c0.1,16.7,0,33.4,0.1,50.1c0,21,5.3,40.2,19.5,56.2c18.8,21.1,25.5,46.3,25.3,73.9
                c-0.1,8-4.5,12.6-12.5,12.6c-28.1,0-56.1,0-84.2,0c-1.3,0-2.7,0-4,0c-2.2,32.2-27.9,46.2-47.9,44.9c-21.7-1.5-41.3-18.5-42.4-44.9
                c-1.3,0-2.6,0-3.9,0c-27.9,0-55.9,0-83.8,0c-8.9,0-13.3-4.4-13.2-13.4c0.3-19.8,2.9-39.1,13.4-56.4c3.6-5.9,7.7-11.7,12.2-17
                c13.8-15.9,19.1-34.7,19.2-55.3c0.1-16.3,0-32.7,0.1-49c0.1-44.9,26.7-82.4,69.1-97.2c0.6-0.2,1.1-0.5,1.9-0.8
                c-3-6.7-4.5-13.5-3.2-20.7c2.6-14.5,12.3-24.7,26.7-27.9c0.7-0.2,1.4-0.5,2.1-0.7C198.9,8,201.9,8,204.9,8z M77.4,323.7
                c82.1,0,163.9,0,246.7,0c-1.5-7.5-2.6-14.7-4.4-21.8c-3.2-12.3-11.1-21.9-18.9-31.5c-13.1-16.1-20.1-34.7-21-55.3
                c-0.9-19.8-0.5-39.6-0.9-59.5c-0.1-5.6-0.5-11.2-1.5-16.7c-7.2-41-48.9-70-89.8-62.6c-39.2,7.1-65.8,39.1-65.9,79
                c0,16.6,0.2,33.2-0.1,49.8c-0.2,14-2.2,27.8-7.3,41c-4.6,11.8-12.1,21.8-20,31.4C83.4,290.9,78,306.2,77.4,323.7z M178.2,346.5
                c-0.7,11.6,9.7,22.2,21.9,22.3c12.4,0.1,22.9-10.2,22.4-22.3C207.8,346.5,193,346.5,178.2,346.5z M200.3,52.9c6,0,11.2-5,11.3-11
                c0.1-6-4.9-11.2-10.9-11.4c-6.2-0.2-11.5,5-11.5,11.2C189.2,47.7,194.2,52.9,200.3,52.9z" />
            <path class="st0" d="M54.6,149.6c3.2-52.7,26.6-93.5,71.8-121.4c6.1-3.8,13-2.2,16.3,3.4c3.3,5.6,1.4,12.1-4.9,15.9
                c-27,16.3-45.4,39.5-55.2,69.6c-3.9,11.9-5.6,24.2-5.8,36.8c-0.1,6.1-4.5,10.8-10.3,11.3c-5.6,0.5-10.9-3.5-11.9-9.2
                C54.3,153.9,54.6,151.7,54.6,149.6z" />
            <path class="st0" d="M346.2,149.5c0,2,0.2,4,0,6c-0.8,5.8-6.1,10.1-11.9,9.6c-6-0.4-10.3-5.2-10.4-11.6c-0.2-16.7-3.4-32.7-10-47.9
                c-10.7-24.8-27.7-44.2-50.9-58c-4.9-2.9-7.2-7.3-6.4-12.1c0.8-4.7,4.3-8.7,9.2-9.1c2.8-0.2,6.1,0.4,8.5,1.8
                c36.8,22.2,59.9,54.4,69,96.4c1.8,8.1,2.4,16.5,3.5,24.8C346.6,149.5,346.4,149.5,346.2,149.5z" />
        </g>
    </svg>
  );
};

export default notificationBell;
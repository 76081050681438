import { httpAPI } from "../index";

const UserAPI = {
  getUserList(query) {
    return httpAPI(`/users?${query || ""}`, "", "GET");
  },
  getUser(id, query) {
    return httpAPI(`/users/${id}?${query || ""}`, "", "GET");
  },
  addUser({ avatar, ...data }) {
    const formdata = new FormData();
    Object.keys(data).forEach((k) => {
      if (data[k]) formdata.append(k, data[k]);
    });
    if (avatar) formdata.append("avatar", avatar);
    return httpAPI(`/users`, formdata, "POST", true);
  },
  updateUser(id, { avatar, ...data }) {
    const formdata = new FormData();
    const includeBlankFields = ["location.address", "status"];
    Object.keys(data).forEach((k) => {
      if (includeBlankFields.includes(k) || data[k])
        formdata.append(k, data[k]);
    });
    if (avatar) formdata.append("avatar", avatar);
    return httpAPI(`/users/${id}`, formdata, "PATCH", true);
  },
  deleteUser(id, params) {
    return httpAPI(`/users/${id}`, params, "delete");
  },
};

export default UserAPI;

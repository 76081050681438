import React, { useEffect, useState } from "react";
import {
  MenuIconWrap,
  MenuItem,
  Navigation,
  SidebarContainer,
  SidebarMenu,
} from "./TheLayout.styles";
import { adminRoutes } from "../../helpers/adminRoutes";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { DashboardSvg } from "../../assets/svgs";
import { StyledLogoLabel } from "../AuthLayout/AuthLayout.styles";

const menu = [
  {
    name: "Invite Client",
    key: "dashboard",
    component: MenuItem,
    icon: DashboardSvg,
    to: adminRoutes.DASHBOARD,
  },
];

const Sidebar = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const location = useLocation();
  useEffect(() => {
    const findKey = (menu, parent = "dashboard") => {
      for (const item of menu) {
        if (item.children) {
          findKey(item.children, `${parent}.${item.key}`);
        } else {
          if (item.to === location.pathname) {
            let path = parent.split(".");
            setActiveKey(item.key);
            setOpenKeys(path.slice(1));
            break;
          }
        }
      }
      return [];
    };
  }, [location.pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const rootSubmenuKeys = menu.map((m) => m.key);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const renderMenu = (menu, className = "") => {
    return menu.map(
      ({ name, key, to, component: Item, icon: Icon, children }, idx) => {
        return (
          <Item
            key={key}
            className={className}
            icon={
              Icon && (
                <MenuIconWrap>
                  <Icon />
                </MenuIconWrap>
              )
            }
            title={children && name}
            level={idx + 1}
          >
            {children ? (
              renderMenu(children, `sub-children ${key}`)
            ) : to ? (
              <Link to={to}>{name}</Link>
            ) : (
              name
            )}
          </Item>
        );
      }
    );
  };

  return (
    <SidebarContainer className="left-panel">
      <StyledLogoLabel>
        <div className="sidebar_logo">
          <div className="label-ant">ANT</div>
          <div className="label-technologies">Technologies</div>
        </div>
      </StyledLogoLabel>
      <Navigation>
        <SidebarMenu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={activeKey}
          onOpenChange={onOpenChange}
        >
          {renderMenu(menu)}
        </SidebarMenu>
      </Navigation>
    </SidebarContainer>
  );
};

export default React.memo(Sidebar);

import axios from "axios";
import interceptor from "./axios";
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3030";

const axiosInstance = (baseURL = API_URL, ...options) => {
  return axios.create({
    baseURL,
    // You can add your headers here
    ...options,
  });
};

export default axiosInstance;

export const httpAPI = (
  url,
  data,
  method = "POST",
  isFormData = false,
  additionalHeaders = {},
  extraProps = {}
) => {
  let headers = {
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    ...additionalHeaders,
  };
  const http = interceptor(axiosInstance(API_URL, { headers }));
  return http({
    url,
    method,
    data,
    headers,
    ...extraProps,
  });
};

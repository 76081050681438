export const ADMIN_BASE_URL = "/";
export const authRoutes = {
  LOGIN: `${ADMIN_BASE_URL}auth/login`,
  REGISTRATION: `${ADMIN_BASE_URL}auth/register`,
  FORGOT_PASSWORD: `${ADMIN_BASE_URL}auth/forget_password`,
  RESET_PASSWORD: `${ADMIN_BASE_URL}auth/reset_password`,

};
export const adminRoutes = {
  DASHBOARD: `${ADMIN_BASE_URL}dashboard`,
  INVITE: `${ADMIN_BASE_URL}invite`,
  PERMISSION: `${ADMIN_BASE_URL}permission`,
  PROFILE: `${ADMIN_BASE_URL}profile`,
  SETTING: `${ADMIN_BASE_URL}setting`,
  MANAGEPASSWORD: `${ADMIN_BASE_URL}manage_password`,
  EDITCLIENT: `${ADMIN_BASE_URL}edit_invite/:id`,
  USER: `${ADMIN_BASE_URL}manage_user`,
 
};

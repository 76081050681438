import { combineReducers } from "@reduxjs/toolkit";
import user from "./user.reducer";
// import theme from "./theme.reducer";

const appReducer = combineReducers({
  user,
  //   theme,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

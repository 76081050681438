import React from "react";

const managePassword = ({
  size = "24",
  color = "#ffffff",
  width = "24",
  height = "24",
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg" fill={color} {...props}>
        <path d="M12.5624 7.28616H12.5235V5.54636C12.5235 2.5209 10.0175 -0.0504197 6.96112 0.000750864C3.98889 0.0519214 1.58001 2.45694 1.58001 5.40564V7.49084C1.58001 7.7403 1.78723 7.94498 2.03977 7.94498H3.5097C3.76224 7.94498 3.96946 7.7403 3.96946 7.49084V7.43967C3.96946 5.81501 3.5097 2.32223 7.12301 2.32223C9.32467 2.32223 10.1276 3.65945 10.1276 5.39925V7.27977H5.5948V7.28616H1.40518C0.62812 7.31175 0 7.93219 0 8.70615V16.5736C0 17.3604 0.647546 18 1.44403 18H12.5559C13.3524 18 14 17.3604 14 16.5736V8.71254C14.0064 7.92579 13.3589 7.28616 12.5624 7.28616ZM7.86121 12.9981C7.75113 13.0748 7.71875 13.158 7.71875 13.2859C7.72523 13.8616 7.72523 14.4372 7.71875 15.0193C7.7317 15.2624 7.60867 15.4926 7.3885 15.6014C6.87694 15.8572 6.36538 15.499 6.36538 15.0193C6.36538 15.0193 6.36538 15.0193 6.36538 15.0129C6.36538 14.4373 6.36538 13.8552 6.36538 13.2795C6.36538 13.1644 6.33948 13.0812 6.23587 13.0045C5.70488 12.6207 5.53005 11.9619 5.79554 11.3798C6.05456 10.8169 6.68268 10.4843 7.27194 10.6059C7.93244 10.7338 8.3922 11.2647 8.39867 11.9107C8.4181 12.3648 8.23031 12.7358 7.86121 12.9981Z" />
    </svg>
  );
};

export default managePassword;
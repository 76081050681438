export const data = [
    {
      name: "Julianne McElroy",
      text: "Invited you to join in the “YL Rockstars” team",
      time: "10 Min ago",
      read: false
    },
    {
      name: "Matt McDonald",
      text: "Assigned you on the call with “Phill Weber”",
      time: "4 Hour ago",
      read: false
    },
    {
      name: "New Lead \"Jessica\" up for grabs!",
      text: "Invited you to join in the “YL Rockstars” team",
      time: "2 days ago",
      read: true
    },
    {
      name: "Ben Harper",
      text: "Invited you to join in the “YL Rockstars” team",
      time: "3 Week ago",
      read: true
    },
    {
      name: "Phil Weber",
      text: "Invited you to join in the “YL Rockstars” team",
      time: "1 Month ago",
      read: true
    },
    {
      name: "Congrats on unlock Tier 3 - Bronze!",
      text: "This is a huge achievement",
      time: "1 Month ago",
      read: true
    },
    {
      name: "Jessica Reynolds",
      text: "Invited you to join in the “YL Rockstars” team",
      time: "2 Month ago",
      read: true
    },
];
import styled from "styled-components";
import { Form, Button } from "antd";

export const StyledTabButtonWrap = styled.div`
  display: inline-block;
  width: 100%;

  .ant-tabs {
    overflow: unset;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 25px;
    padding: 0 30px;
    margin-left: -30px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    max-width: 476px;
    width: 100%;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 50px;
    justify-content: center;
    @media (max-width: 767px) {
      height: 36px;
      max-width: 340px;
      min-width: 264px;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 30px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #949494;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-tabs-ink-bar {
    height: 2px;
    background: ${(props) => props.theme.colors.primary} !important;
    border-radius: 50px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      max-width: 362px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 24px;
    }

    .ant-tabs-tab-btn {
      font-size: 14px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 14px;
    }
  }

  @media (max-width: 991.98px) {
    .ant-tabs-top > .ant-tabs-nav {
      display: flex;
      // flex-flow: column;
      // justify-content: center;
      // align-items: flex-end;
      margin-bottom: 80px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      justify-content: space-evenly;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      // margin-bottom: 35px;

      width: 100%;
      // justify-content: center;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 14px;
    }
  }

  @media (max-width: 767px) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      padding: 0 15px;
    }
  }
  @media (max-width: 451px) {
    .ant-tabs-top > .ant-tabs-nav {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin: 20px 0 36px 0;
  background-color: ${(props) => props.theme.colors.primary};
`;
export const StyledAnotherPrimaryButton = styled.div`
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const StyledFormButton = styled.div`
  display: flex;
  .ant-btn {
    margin-right: "50px";
  }
`;

export const StyledContentTitle = styled.h3`
  font-weight: bold;
  font-size: 34px;
  line-height: 28px;
  color: #232333;
  text-align: center;
  margin-top: 39px;
  margin-bottom: 40px;
  width: 100%;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledFormControl = styled.div`
  margin: 0px 45px;
  .ant-form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .custom-textbox {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      align-self: flex-start;
    }
  }

  @media (max-width: 575.98px) {
    margin: 0;
  }
`;

export const StyledFormItem = styled.div`
  @media (max-width: 991.98px) {
    width: 100%;
  }
`;

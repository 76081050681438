import styled from "styled-components";
import { Container } from "../Layout/Container";

export const Card = styled.div`
  /* padding: ${({ padding }) => padding || "15px"}; */
  padding: ${({ padding }) => padding || "30px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#ffffff")};
  border-radius: 10px;
  width: ${({ width }) => width || "100%"};
  @media (max-width: 991px) {
    padding: ${({ padding }) => padding || "15px"};
  }
`;

export const SmallCard = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#ebd6d6")};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "168px"};
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
`;

export const CardTitle = ({ containerStyle = {}, title, children, props }) => (
  <Container {...containerStyle}>
    <CardTitleText {...props}>{title || children}</CardTitleText>
  </Container>
);

export const CardTitleText = styled.span`
  font-weight: bold;
  font-size: 34px;
  line-height: 28px;
  color: #232333;
  padding-bottom: 6px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #232333;
    border-radius: 5px;
    height: 1px;
    width: 100%;
  }
`;

export const LineSeparator = styled.div`
  background: #232333;
  border-radius: 5px;
  width: ${({ width }) => width || 0}px;
  height: 1px;
  display: block;
  margin-top: 14px;
  margin-bottom: 14px;
`;

import { Card } from "antd";
import styled from "styled-components";
import { imagePath } from "../../utils/fn";
import { UserAvatar } from "../../ui/Avatar";
const { Meta } = Card;

export const UserCard = ({ firstName, lastName, avatar, location }) => {
  const avatarProps = {
    ...(avatar?.path
      ? { src: avatar?.isStatic ? avatar.path : imagePath(avatar?.path) }
      : { fullName: `${firstName || ""} ${lastName || ""}` }),
  };
  return (
    <StyledHeroCardUserDerails
      // avatar={<Avatar size={53} {...avatarProps} />}
      avatar={<UserAvatar size={53} {...avatarProps} />}
      title={`${firstName || ""} ${lastName || ""}`}
      description={location?.address || ""}
    />
  );
};

export const StyledHeroCardUserDerails = styled(Meta)`
  margin-right: 60px;

  :last-child {
    margin-right: 0;
  }

  .ant-avatar {
    /* outline: 1px solid ${(props) => props.theme.colors.primary}; */
    /* outline-offset: -1px; */
    background: #ccc;
  }
  .ant-card-meta-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #232333;
    white-space: normal;
    /* white-space: nowrap; */
    margin-bottom: 0px !important;
  }
  .ant-card-meta-description {
    font-size: 14px;
    line-height: 22px;
    color: #747487;
    white-space: normal;
    /* white-space: nowrap; */
  }

  @media (max-width: 1399.98px) {
    margin-right: auto;

    .ant-card-meta-title {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (max-width: 767.98px) {
    margin-right: 0;

    .ant-avatar {
      height: 38px !important;
      width: 38px !important;
      line-height: 38px !important;
      .ant-avatar-string {
        line-height: 38px !important;
        font-size: calc(${({ size }) => size || 32}px / 2.3);
      }
    }

    .ant-card-meta-title {
      font-size: 14px;
      line-height: 18px;
    }

    .ant-card-meta-description {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (max-width: 575.98px) {
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

import React from "react";

const upload = ({ color = "#D3D6DD", ...props }) => {
  return (
    <svg
      width="41"
      height="28"
      viewBox="0 0 41 28"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M33.9639 12.1875C34.2139 11.5 34.4014 10.8125 34.4014 10C34.4014 6.6875 31.7139 4 28.4014 4C27.1514 4 25.9639 4.375 25.0264 5.0625C23.3389 2.0625 20.0889 0 16.4014 0C10.8389 0 6.40137 4.5 6.40137 10C6.40137 10.1875 6.40137 10.375 6.40137 10.5625C2.90137 11.75 0.401367 15.125 0.401367 19C0.401367 24 4.40137 28 9.40137 28H32.4014C36.7764 28 40.4014 24.4375 40.4014 20C40.4014 16.1875 37.6514 12.9375 33.9639 12.1875ZM24.9639 16H20.9014V23C20.9014 23.5625 20.4014 24 19.9014 24H16.9014C16.3389 24 15.9014 23.5625 15.9014 23V16H11.7764C10.9014 16 10.4639 14.9375 11.0889 14.3125L17.6514 7.75C18.0264 7.375 18.7139 7.375 19.0889 7.75L25.6514 14.3125C26.2764 14.9375 25.8389 16 24.9639 16Z" />
    </svg>
  );
};

export default upload;

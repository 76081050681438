import React from "react";

const notificationSetting = ({
  size = "24",
  color = "#ffffff",
  width = "24",
  height = "24",
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill={color} {...props}>
        <path d="M0 15.6826H8.51064C8.41779 10.9396 12.3017 9.24109 14.2553 8.98465C14.8936 3.31862 9.63249 2.04282 9.45841 1.98654V1.35113C9.45841 1.1653 9.4364 0.977527 9.35513 0.809249C9.12248 0.327668 8.69513 -0.0145779 8.08511 0.00141451C7.38808 -0.0300321 6.94921 0.469253 6.76839 0.860612C6.70588 0.995902 6.69246 1.14574 6.69246 1.294V1.98654C3.46228 2.71824 2.09542 5.74514 1.89555 7.16476V12.9059L0 14.7445V15.6826Z"/>
        <path d="M6.22852 16.6582H8.60763L9.12987 18.1591C7.14921 19.0447 6.37037 17.5275 6.22852 16.6582Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0873 18.7969C17.0529 18.7969 18.6463 17.2513 18.6463 15.3447C18.6463 13.4382 17.0529 11.8926 15.0873 11.8926C13.1217 11.8926 11.5283 13.4382 11.5283 15.3447C11.5283 17.2513 13.1217 18.7969 15.0873 18.7969ZM15.0873 16.8082C15.9205 16.8082 16.596 16.153 16.596 15.3447C16.596 14.5365 15.9205 13.8813 15.0873 13.8813C14.2541 13.8813 13.5786 14.5365 13.5786 15.3447C13.5786 16.153 14.2541 16.8082 15.0873 16.8082Z" />
        <path d="M13.8328 19.7374C13.8533 19.8865 13.9845 19.9978 14.1395 19.9978H15.9628C16.1156 19.9978 16.2456 19.8896 16.2687 19.743L16.5031 18.2586C16.5317 18.0776 16.388 17.9141 16.1992 17.9129L13.9346 17.8987C13.7465 17.8975 13.6009 18.0579 13.6258 18.2387L13.8328 19.7374Z" />
        <path d="M13.8328 10.9525C13.8533 10.8034 13.9845 10.6921 14.1395 10.6921H15.9628C16.1156 10.6921 16.2456 10.8004 16.2687 10.9469L16.5031 12.4313C16.5317 12.6123 16.388 12.7758 16.1992 12.777L13.9346 12.7912C13.7465 12.7924 13.6009 12.632 13.6258 12.4512L13.8328 10.9525Z" />
        <path d="M18.3706 18.581C18.514 18.6382 18.6789 18.5838 18.7564 18.4535L19.6681 16.9219C19.7445 16.7935 19.7129 16.6303 19.5936 16.5375L18.3855 15.5984C18.2381 15.4839 18.0203 15.5229 17.9249 15.6809L16.7799 17.5761C16.6848 17.7335 16.7552 17.9361 16.9291 18.0055L18.3706 18.581Z" />
        <path d="M10.5297 14.1841C10.4069 14.0923 10.3731 13.9265 10.4507 13.7962L11.3623 12.2646C11.4387 12.1363 11.6003 12.0812 11.7427 12.135L13.1852 12.6804C13.3611 12.7469 13.4352 12.9493 13.3419 13.1085L12.2223 15.0179C12.1293 15.1765 11.9133 15.2186 11.7644 15.1073L10.5297 14.1841Z" />
        <path d="M10.5411 16.5195C10.4181 16.611 10.3838 16.7767 10.461 16.9071L11.3689 18.4409C11.445 18.5694 11.6064 18.6248 11.7489 18.5713L13.1928 18.0294C13.3689 17.9633 13.4435 17.761 13.3505 17.6016L12.2357 15.6896C12.1431 15.5308 11.9272 15.4881 11.7779 15.5992L10.5411 16.5195Z" />
        <path d="M18.364 12.0922C18.5075 12.0353 18.6723 12.0902 18.7494 12.2206L19.6573 13.7543C19.7334 13.8829 19.7014 14.0461 19.5819 14.1385L18.3714 15.0748C18.2238 15.1889 18.0061 15.1494 17.9111 14.9912L16.7707 13.0934C16.676 12.9358 16.7469 12.7334 16.921 12.6644L18.364 12.0922Z" />
    </svg>
  );
};

export default notificationSetting;
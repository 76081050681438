import { httpAPI } from "../index";

const CLientAPI = {
  addClient(formdata) {
    // return httpAPI(`/client`, params, "post");
  return httpAPI(`/client`, formdata, "POST", true);

   },
  
 
  getClientList(query) {
    return httpAPI(`/client?${query || ""}`, "", "GET");
  },
  getClientListDetail(id) {
    return httpAPI(`/client/${id}`, "", "get");
  },
  updateClient(id, params) {
    return httpAPI(`/client/${id}`, params, "patch");
  },
  deleteClient(id, params) {
    return httpAPI(`/client/${id}`, params, "delete");
  },
};

export default CLientAPI;

import React from "react";
import styled from "styled-components";
import { Flex } from "../Layout/Flex";
import { UserAvatar } from "../../ui/Avatar";

const UserAvatarAndFullName = ({ image, name }) => {
  const avatarProps = {
    ...(image ? { src: image } : { fullName: name }),
  };
  return (
    <Flex>
      {/* <Avatar {...avatarProps} /> */}
      <UserAvatar {...avatarProps} />
      <UserName>{name}</UserName>
    </Flex>
  );
};

const UserName = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #747487;
  margin: 0 15px;
`;

export default UserAvatarAndFullName;

// Content

import { Link, Redirect, Switch } from "react-router-dom";
import { adminRoutes } from "../../helpers/adminRoutes";
import React from "react";

import { Route, useLocation } from "react-router";
import Dashboard from "../../Components/Dashboard/Dashboard.jsx";
import InviteClient from "../../Components/InviteClient/InviteClient.jsx";
import Notification from "../../Components/Setting/Setting";

import { Breadcrumb } from "antd";
import { BreadCrumbsWrapper } from "./TheLayout.styles";
import Permission from "../../Components/Permission";
import ManagePassword from "../../Components/ManagePassword/ManagePassword";

const breadcrumbNameMap = {
  "/dashboard": "Dashboard",
  "/invite": "Invite Client",
};

const AdminRoutes = ({ ...props }) => {
  return <Route {...props} />;
};

const Content = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <div>
      {Object.keys(breadcrumbNameMap).includes(location.pathname) && (
        <BreadCrumbsWrapper>
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      )}
      <Switch>
        <Route
          exact={true}
          path={adminRoutes.SETTING}
          component={Notification}
        />
        <Route
          exact={true}
          path={adminRoutes.DASHBOARD}
          component={Dashboard}
        />
        <Route
          exact={true}
          path={adminRoutes.INVITE}
          component={InviteClient}
        />
         <Route
          exact={true}
          path={adminRoutes.MANAGEPASSWORD}
          component={ManagePassword}
        />
        <Route
          exact={true}
          path={adminRoutes.PERMISSION}
          component={Permission}
        />
          <Route
          exact={true}
          path={adminRoutes.EDITCLIENT}
          component={InviteClient}
        />
        <Redirect from="/" to={adminRoutes.DASHBOARD} />
      </Switch>
    </div>
  );
};

export default React.memo(Content);

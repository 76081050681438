import React from "react";
import styled from "styled-components";
import { Card } from "../Card";
import { Image, Avatar, Form, Col } from "antd";
import { FormSelect } from "../Form";
import { PrimaryButton } from "../Button";

export const EventCard = ({
  eventName,
  eventAt,
  eventBy,
  eventUsers,
  eventImage,
}) => {
  return (
    <>
      <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
        <StyledEventCardWrapper>
          <StyledImageWrapper>
            <Image src={eventImage} preview={false} />
          </StyledImageWrapper>
          <StyledAllContentCardWrapper>
            <StyledContentCardWrapper>
              <h3>{eventName}</h3>
              <h4>{eventAt}</h4>
              <span>{eventBy}</span>
            </StyledContentCardWrapper>

            <StyledAvatarGroupAllWrapper>
              <StyledAvatarGroupWrapper>
                {eventUsers.slice(0, 4).map((item) => (
                  <StyledAvatarGroup maxCount={0} key={item.id}>
                    <Avatar src={item.avatar} />
                  </StyledAvatarGroup>
                ))}
              </StyledAvatarGroupWrapper>

              {eventUsers.length >= 5 && (
                <Avatar
                  style={{
                    color: "#0061A4",
                    backgroundColor: "#F3F5F4",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "15px",
                    marginLeft: "-10px",
                    border: "1px solid #747487",
                  }}
                >
                  {eventUsers.length > 99 ? "+99" : `+${eventUsers.length - 4}`}
                </Avatar>
              )}
            </StyledAvatarGroupAllWrapper>
            <Form layout="vertical">
              <StyledSelectItemWrapper name="RSVP" label="RSVP">
                <FormSelect
                  placeholder="Select type"
                  options={[
                    { label: "I'm Going", value: "I'm Going" },
                    {
                      label: "Interested",
                      value: "Interested",
                    },
                    { label: "Next Time", value: "Next Time" },
                  ]}
                />
              </StyledSelectItemWrapper>
            </Form>
            <StyledRsvpButtonWrapper>
              <PrimaryButton>RSVP</PrimaryButton>
            </StyledRsvpButtonWrapper>
          </StyledAllContentCardWrapper>
        </StyledEventCardWrapper>
      </Col>
    </>
  );
};

export const StyledEventCardWrapper = styled(Card)`
  display: flex;
  flex-flow: column;
  padding: 0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  height: 100%;

  .ant-image {
    display: inline-block;
    width: 100%;
  }
  .ant-image-img {
    border-radius: 10px 10px 0px 0px;
  }
`;

export const StyledAllContentCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 10px 15px 15px 15px;
`;

export const StyledContentCardWrapper = styled.div`
  display: flex;
  flex-flow: column;

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #232333;
    min-height: 40px;
  }

  h4 {
    font-size: 12px;
    line-height: 15px;
    color: #747487;
    margin-bottom: 9px;
  }

  span {
    font-size: 12px;
    line-height: 14px;
    color: #747487;
    margin-bottom: 18px;
  }
`;

export const StyledAvatarGroupAllWrapper = styled.div`
  display: flex;
`;

export const StyledAvatarGroupWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  .ant-avatar-group:first-child {
    margin-left: 0px;
  }
`;

export const StyledAvatarGroup = styled(Avatar.Group)`
  margin-left: -10px;

  .ant-avatar {
    border: 1px solid #747487;
  }
`;

export const StyledSelectItemWrapper = styled(Form.Item)`
  margin-bottom: 20px;
  label {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #232333;
  }
`;

export const StyledRsvpButtonWrapper = styled.div`
  display: inline-block;
`;

import { SecondaryButton } from "./";
import styled from "styled-components";
import { HiPlus } from "react-icons/hi";

export const SecondaryMobileButtonWrapper = styled.div`
  display: none;

  .plus_icon {
    font-size: 34px;
  }

  @media (max-width: 767px) {
    display: block;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 11;
  }
`;

export const SecondaryButtonWrapper = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const SecondaryFloatButton = ({ onClick, buttonText }) => {
  return (
    <>
      <SecondaryMobileButtonWrapper onClick={onClick}>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
        />
      </SecondaryMobileButtonWrapper>
      <SecondaryButtonWrapper onClick={onClick}>
        <SecondaryButton>{buttonText}</SecondaryButton>
      </SecondaryButtonWrapper>
    </>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ padding }) => padding || "17px 30px 30px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "inherit")};

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
`;

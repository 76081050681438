import { Form, Input } from "antd";
import styled from "styled-components";

export const FormItem = styled(Form.Item)`
  margin-bottom: 13px;
  min-width: 210px;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "100%")};
  label {
    color: ${({ color }) => (color ? color : "#232333")};
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    /* max-width: 822px; */
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "100%")};
  }
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "822px")};
  background: #f6f7f9;
  input {
    background: #f6f7f9;
  }
  color: #747487;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  color: #747487;
  min-height: 48px;
  &:hover {
    border-color: #e5e5e5;
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}23;
  }
`;

export const FormInputPassword = styled(Input.Password)`
  width: 100%;
  max-width: 822px;
  background: #f6f7f9;
  input {
    background: #f6f7f9;
  }
  color: #747487;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  color: #747487;
  min-height: 48px;
  &:hover {
    border-color: #e5e5e5;
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}23;
  }
`;

export const FormTextArea = styled(Input.TextArea)`
  &.ant-input {
    background: #f6f7f9;
    width: 100%;
    /* max-width: 822px; */
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "822px")};
    color: #747487;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    min-height: 98px;
    &textarea {
      max-width: 822px !important;
    }
    &:hover {
      border-color: #e5e5e5;
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}23;
    }
  }
`;

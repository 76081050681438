import { Upload } from "antd";
import React from "react";
import styled from "styled-components";
import { UploadSvg } from "../../assets/svgs";

export const FileUpload = ({ children, text = "Image upload", ...props }) => {
  return (
    <FileUploadDragger showUploadList={true} {...props}>
      {children && children}
      {!children && (
        <UploadChildWrap>
          <span>
            <UploadSvg />
          </span>
          <p>{text}</p>
        </UploadChildWrap>
      )}
    </FileUploadDragger>
  );
};

const FileUploadDragger = styled(Upload.Dragger)`
  &.ant-upload-drag {
    border: 1px solid #e5e5e5;
    background: #f6f7f9;
    width: 100%;
    max-width: 822px;
    .ant-upload-drag-container {
      box-sizing: border-box;
      border-radius: 5px;
      min-height: 172px;
      padding: 32px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &:hover {
      border-color: #e5e5e5 !important;
    }

    .ant-upload {
      padding: 0;
    }
  }
`;

const UploadChildWrap = styled.div`
  background: #ffffff;
  border: 2px dashed #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 284px;
  min-height: 108px;
  cursor: pointer;

  @media (max-width: 467px) {
    min-width: 100%;
  }

  span {
    display: flex;
    justify-content: center;
    margin: 29px 29px 10px;
    color: #d3d6dd;
    img {
      width: 42px !important;
      height: 24px !important;
    }
  }
  p {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #949494;
    text-align: center;
  }
`;

import styled from "styled-components";
import { Image } from "antd";
// import { EarthSvg } from "../../assets/svgs";
import { Card, UserCard } from "../Card";
// import { PrimaryButton } from "../../ui/Button";
// import {
//   CardHeading,
//   CardSubHeading,
//   CardSubHeadingSpan,
// } from "../../ui/Heading";
import { Capitalize, nFormatter } from "../../utils/fn";
// import { fallbackImage } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { adminRoutes } from "../../helpers/adminRoutes";
import { useSelector } from "react-redux";
import { message } from "antd";

export const HeroCard = ({
  imageSrc,
  imageWidth,
  imageHeight,
  cardHeading,
  cardGroup,
  cardMember,
  actionButton,
  cardDescription,
  users,
  earthIconSize,
  item,
}) => {
  const { user } = useSelector((state) => state.user);

  const history = useHistory();

  const actionHandler = () => {
    if (user?.teams.map((team) => team.team._id).includes(item?._id)) {
      history.push(adminRoutes.TEAMDETAILS.replace(":name", item?._id));
    } else {
      message.info("Not enough permission to visit the community");
    }
  };

  return (
    <StyledHeroCardWrapper>
      <StyledHeroCardContentWrapper>
        <StyledImageWrapper>
          <StyledImage
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            src={imageSrc}
            preview={false}
            objectFit={imageSrc ? "cover" : "cover"}
            // fallback={fallbackImage}
            onClick={actionHandler}
          />
        </StyledImageWrapper>
        <StyledCardRightSideWrapper>
          <StyledUserHeaderTextWrap>
            <StyledCardHeadingWrapper className="heading_wrap">
              <StyledHeading onClick={actionHandler} className="heading">
                <h2>{cardHeading}</h2>
                <MobileActionButton>{actionButton}</MobileActionButton>
              </StyledHeading>

              {/* <StyledSubHeading className="sub_heading">
                <StyledEarthIcon
                  iconHeight={earthIconSize}
                  iconWidth={earthIconSize}
                />
                <p>
                  <span>{Capitalize(cardGroup)} Community</span> -{" "}
                  {nFormatter(cardMember)} member
                  {cardMember > 1 ? "s" : ""}
                </p>
              </StyledSubHeading> */}
            </StyledCardHeadingWrapper>
            <ActionButtonWrapper className="action_button">
              {actionButton}
            </ActionButtonWrapper>
          </StyledUserHeaderTextWrap>
          <StyledDescriptionWrappper>
            <StyledHeroCardDescription>
              {cardDescription}
            </StyledHeroCardDescription>
          </StyledDescriptionWrappper>
          <StledUserCardWrapper>
            {users.map((item) => (
              <UserCard {...item} />
            ))}
          </StledUserCardWrapper>
        </StyledCardRightSideWrapper>
      </StyledHeroCardContentWrapper>

      <StledUserCardMobileWrapper>
        {users.map((item) => (
          <UserCard {...item} />
        ))}
      </StledUserCardMobileWrapper>
    </StyledHeroCardWrapper>
  );
};

export const StyledHeroCardWrapper = styled(Card)`
  margin-bottom: 30px;
  width: 100%;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  /* padding: 30px; */
  padding: 24px 30px 24px 13px;

  @media (max-width: 575.98px) {
    padding: 13px;
  }
`;

export const StyledHeroCardContentWrapper = styled.div`
  display: flex;
  width: 100%;

  .ant-image {
    margin-right: 30px;
  }

  @media (max-width: 1199.98px) {
    margin-bottom: 20px;
    .ant-image {
      margin-right: 0px;
      height: 100%;
    }
  }

  @media (max-width: 575.98px) {
    flex-flow: column;
    margin-bottom: 0;
    .ant-image {
      width: 100%;
    }
  }
`;

export const StyledImageWrapper = styled.div`
  display: flex;

  @media (max-width: 1199.98px) {
    align-items: baseline;
    margin-right: 15px;
  }

  @media (max-width: 575.98px) {
    margin-bottom: 15px;
    margin-right: 0;
  }
`;

const StyledImage = styled(Image)`
  display: inline-block;
  /* width: 337px;
  height: 337px; */
  width: 315px;
  height: 315px;
  object-fit: ${({ objectFit }) => objectFit || "cover"};
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 1199.98px) {
    height: 267px;
    width: 267px;
  }

  @media (max-width: 991.98px) {
    height: 255px;
    width: 255px;
  }

  @media (max-width: 767.98px) {
    height: 198px;
    width: 198px;
  }

  @media (max-width: 575.98px) {
    width: 100%;
    object-fit: cover;
  }
`;

export const StyledCardRightSideWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  /* padding: 11px 0px; */

  @media (max-width: 1199.98px) {
    flex: 1;
    padding: 0px;
  }

  @media (max-width: 575.98px) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const StledUserCardWrapper = styled.div`
  display: flex;

  @media (max-width: 1199.98px) {
    display: none;
  }
`;

export const StyledUserHeaderTextWrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  /* margin-bottom: 20px; */
  /* align-items: center; */

  @media (max-width: 1199.98px) {
    margin-bottom: 10px;
    justify-content: space-between;
  }

  @media (max-width: 575.98px) {
    display: inline-block;
  }
`;

export const StyledCardHeadingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const StyledHeading = styled.div`
  display: flex;
  cursor: pointer;
  h2 {
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #232333;
    margin-right: 30px;
    margin-bottom: 0px;
  }

  @media (max-width: 1399.98px) {
    h2 {
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (max-width: 767.98px) {
    h2 {
      font-size: 14px;
    }
  }

  @media (max-width: 575.98px) {
    width: 100%;
  }
`;

export const StyledSubHeading = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 18px;
    line-height: 28px;
    color: #747487;
    margin-bottom: 0px;
  }
  span {
    font-weight: bold;
  }

  /* span {
    font-size: 18px;
    line-height: 28px;
    color: #747487;
  } */

  @media (max-width: 1399.98px) {
    span {
      font-size: 14px;
    }
  }

  @media (max-width: 767.98px) {
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }

  @media (max-width: 575.98px) {
    span {
      font-size: 12px;
    }
  }
`;

// export const StyledEarthIcon = styled(EarthSvg)`
//   width: ${({ iconWidth }) => (iconWidth ? iconWidth : "18px")};
//   height: ${({ iconHeight }) => (iconHeight ? iconHeight : "18px")};
//   margin-right: 5px;

//   @media (max-width: 1399.98px) {
//     font-size: 14px;
//   }

//   @media (max-width: 767.98px) {
//     width: 10px;
//     height: 10px;
//   }

//   @media (max-width: 575.98px) {
//     width: 12px;
//     height: 12px;
//   }
// `;

export const ActionButtonWrapper = styled.div`
  float: right;
  margin-left: auto;

  /* font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: red; */

  @media (max-width: 575.98px) {
    display: none;
  }
`;

export const MobileActionButton = styled.div`
  display: none;
  @media (max-width: 575.98px) {
    display: block;
    float: right;
    margin-left: auto;
  }
`;

export const StyledDescriptionWrappper = styled.div`
  display: flex;

  @media (max-width: 575.98px) {
    margin-bottom: 20px;
  }
`;

export const StyledHeroCardDescription = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #747487;
  margin-bottom: 50px;
  /* margin-bottom: 53px; */
  /* margin-bottom: 48px; */
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 154px;
  width: 100%;

  @media (max-width: 1199.98px) {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0px;
    -webkit-line-clamp: 11;
    height: auto;
  }

  @media (max-width: 767.98px) {
    font-size: 12px;
    -webkit-line-clamp: 8;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
    line-height: 19px;
    -webkit-line-clamp: 8;
  }
`;

export const StledUserCardMobileWrapper = styled.div`
  display: flex;
  display: none;

  @media (max-width: 1199.98px) {
    display: block;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 575.98px) {
    flex-flow: column;
  }
`;

import React, { useState } from "react";
import { Button, Form, Input, Upload, Avatar, Badge, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import styled from "styled-components";

import { CameraSvg, UserSvg } from "../assets/svgs";
import { FileUploadAvtar } from "../ui/Form/FileUploadAvtar";

const IconContainer = styled.div`
  .anticon {
    &.ant-scroll-number-custom-component {
      background: ${(props) => props.theme.colors.primary};
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 10px;
        width: 10px;
      }
    }
  }
`;

const StyledForgotPassword = styled.ul`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
`;

const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
  margin-top: 60px;
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  @media screen and (max-width: 959px) {
    margin-top: 26px;
  }
`;
const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

const StyledForms = styled.div`
  // padding-top: 32px;
  margin-top: -50px;
  @media screen and (max-width: 959px) {
    margin-top: 16px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
`;
const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  font-family: Mulish;
  font-style: normal;
  font-size: 34px;
  line-height: 28px;
  color: #232333;
  @media screen and (max-width: 959px) {
    display: none;
  }
`;

const StyledLink = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #747487;
  font-size: 14px;
  color: #747487;
  margin-left: 4px;
  font-weight: 600;
`;

const StyledFormFirstLastNameItem = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 595px) {
    flex-direction: column;
  }
`;

const StyledFirstNameFormItem = styled.div`
  margin-right: 30px;
  width: 100%;
  @media screen and (max-width: 595px) {
    margin-right: 0px;
  }
  .ant-form-item {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 220px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        max-width: unset;
        width: 100%;
      }
    }
  }
`;

const StyledLastNameFormItem = styled.div`
  width: 100%;
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 220px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        max-width: unset;
        width: 100%;
      }
    }
  }
`;

const StyledFormItem = styled.div`
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 470px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
`;

const StyledFormUploadItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const Registration = () => {
  const history = useHistory();
  const [state, setState] = useState({
    image: "",
    image_src: "",
  });

  const onFinish = (values) => {
    console.log(values);
    history.push("/login");
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }
    getBase64(file, (base64) =>
      setState((s) => ({
        ...s,
        image_src: base64,
        image: file,
      }))
    );
    return false;
  };

  return (
    <StyledForm>
      <StyledFormsLabel>Join</StyledFormsLabel>
      <StyledForms>
        <Form
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={"optional"}
        >
          <StyledFormUploadItem>
            <FileUploadAvtar
              uploadSize={"64px"}
              cameraIcon={"10px"}
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              size={64}
              userIcon={"36px"}
              offset={[-6, 52]}
              image_src={state?.image_src}
              badgeSize={"24px"}
            />
          </StyledFormUploadItem>

          <StyledFormFirstLastNameItem>
            <StyledFirstNameFormItem>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "This is required" }]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </StyledFirstNameFormItem>
            <StyledLastNameFormItem>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "This is required" }]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </StyledLastNameFormItem>
          </StyledFormFirstLastNameItem>
          <StyledFormItem>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem>
            <Form.Item
              name="memberNumber"
              label="Member Number"
              // rules={[
              //     { required: true, message: "This is required" },
              // ]}
            >
              <Input placeholder="Enter member number" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem>
            <Form.Item
              name="password"
              label="Password"
              className="password-w-icon"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
                {
                  min: 8,
                  message: "password should contain minimum 8 characters",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={
                  <img
                    src="/assets/images/password-icon.png"
                    alt=""
                    className="user-icon"
                  />
                }
              />
            </Form.Item>
          </StyledFormItem>

          <StyledFormItem>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={
                  <img
                    src="/assets/images/password-icon.png"
                    alt=""
                    className="user-icon"
                  />
                }
              />
            </Form.Item>
          </StyledFormItem>
          <Form.Item>
            <StyledButton htmlType="submit">Register</StyledButton>
          </Form.Item>
          <Form.Item>
            <StyledLink>
              Already have an account?
              {"   "}{" "}
              <Link className="" to={"login"}>
                <StyledForgotPassword>Login</StyledForgotPassword>
              </Link>
            </StyledLink>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
};

export default Registration;
